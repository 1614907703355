//import { Fancybox } from "@fancyapps/ui/dist/fancybox.esm";
require("./bootstrap");
require('bootstrap/js/dist/collapse');
//require('dragscroll/dragscroll.js');

//Alerts
setTimeout(() => {
    $('.alert.notification').remove();
}, 4000);

//PROGRESSIVE SCROLL BAR
$(document).ready(function () {
    var docHeight = $(document).height(),
        windowHeight = $(window).height(),
        scrollPercent;

    $(window).scroll(function () {
        scrollPercent = $(window).scrollTop() / (docHeight - windowHeight) * 100;
        $('.scroll-progress').width(scrollPercent + '%');
    });
});

// Add Class Images With link
$('body .contentBody a img').addClass("notZoom");
$('.contentBody figure img').on('click', function(){
    var link = $(this).parent().find('figcaption a');
    if(link){
        $(link)[0].click();
        return false;
    }
})

//Zoom image Articles
//Fancybox.bind("body .contentBody img:not(.notZoom)");

//Fixed Header Scroll
var didScrollDesk;
var lastScrollTopDesk = 0;
var deltaDesk = 5;

$(window).scroll(function(event){
    didScrollDesk = true;
});

setInterval(function() {
    if (didScrollDesk) {
        
        hasScrolled();
        didScrollDesk = false;
    }
}, 250);

function hasScrolled() {
    var st = $(document).scrollTop();
    
    if(Math.abs(lastScrollTopDesk - st) <= deltaDesk)
        return;
        
    if (st > lastScrollTopDesk && st>=200){
        $('body').addClass('scroll-up');
    } else {
        if(st + $(window).height() < $(document).height()) {
            $('body').removeClass('scroll-up');
        }
    }
    lastScrollTopDesk = st;
}


//Get more posts
let enabled = true; 
let page = 1;
var	totalPage = $('input[name=totalPage]').val();
var	affiliateId = $('input[name=affiliateId]').val();
var	categoryId = $('input[name=categoryId]').val();
$('.btnViewMore').on('click', function(el){
    enabled = false;
    page++;
    var arrayData = {
        'page' : page,
        'affiliateId': affiliateId,
        'categoryId': categoryId
    }

    $.ajax({
        url: "/posts/get",
        type: "GET",
        data: arrayData,
        async: true,
        //dataType: "html",
        success: function (data) {
            enabled = true;
            if(page == totalPage){
                $('.btnViewMore').parents('.sectionBtnMore').hide();
            }
            $('.listCards .cards > ul > li:last').after(data);
         
        }
    });
})

//Show More Author Description
$('.showMoreDescription').on('click', function(){
    $(this).siblings('.author-description').addClass('d-block')
    $(this).hide()
})

//GLOSSARY PAGE
$('.glossaries .filters .alphas ul li').on('click', function(){
    $('.glossaries .filters .alphas ul li').removeClass('active');
    $(this).addClass('active');
})
$('.glossaries .data .group li .topBody').on('click', function(){
    var checkHasClass = $(this).parent().hasClass('active');
    $('.glossaries .data .group li,  .glossaries .data').removeClass('active');

    if(!checkHasClass){
        $(this).parent().addClass('active');
        $(this).parent().parents('.data').addClass('active');
    }
})
$(".glossaries #searchbar").on('keyup', function(){
    var value = $(this).val().toLowerCase();
    $(".glossaries .group li .name").each(function () {
        if ($(this).text().toLowerCase().search(value) > -1) {
            $(this).parents('li').show();
            //$(this).prev('.country').last().show();
        } else {
            $(this).parents('li').hide();
        }
    });   
})

//MODAL
$('.closeAction').on('click', function(){
    $(this).parents('.modal').hide();
})

$('.openModal').on('click', function(){
    var modal = $(this).data('modal');
    $('.' + modal).show();
})

//SLIDER
/*  */

//POST-ANCHOR
$(".modal .body li a.anchor").each(function( index ) {
    var textH2 = $(this).data('url');
    $(this).attr("href", "#"+string_to_slug(textH2));
    $(this).attr("id", "mob_"+string_to_slug(textH2));
});

$('.modal .body li a').on('click', function(){
    $('.modal').hide();
})

$( ".contentBody h2" ).each(function( index ) {
    var textH2 = $( this ).text();
    $( this ).attr("id", string_to_slug(textH2));
    $( this ).addClass("elementAnchor");
});


$(".detailsArticle .sidebarNav ul li a.anchor").each(function( index ) {
    var textH2 = $( this ).data('url');
    $( this ).attr("href", "#"+string_to_slug(textH2));
    $( this ).attr("id", "mob_"+string_to_slug(textH2));
});

var clickHash = false;
var timeOutHash = "";

var offsetAnchor = 0;


$('.detailsArticle .sidebarNav ul li a.anchor').click(function(event) {
	event.preventDefault();
    var sectionTo = $(this).attr('href');
    var anchorMenuHash = $(this).attr('id');
    clickHash = true;
    offsetAnchor = 170;
    
    $('html, body').animate({scrollTop: $(sectionTo).offset().top-offsetAnchor}, 500);

    clearTimeout(timeOutHash);
    timeOutHash = setTimeout(function(){
	    clickHash=false;
    }, 700);
    
});


var timeoutScrollAnchor = "";
var anchorActive="";

$(document).scroll(function () {
    
    if($('.sidebarNav .menuAnchors')[0]){
        var anchorMenuMobile = $('.sidebarNav');
        var menuAnchors = $('.sidebarNav .menuAnchors');
        
        var topBodyScroll = $('.infosPost .summary').offset().top;
        
        var st = $(this).scrollTop();	
        if(st>=topBodyScroll){
            $('.emptySideBarNav').show();
            menuAnchors.removeClass("hideAnchor");
            anchorMenuMobile.addClass("fixed");
            $('.sidebarNav').addClass('active');
            $('.sidebarNav:after').show();

        }else{
            $('.detailsArticle .sidebarNav ul li a.anchor').removeClass("active");
            $('.emptySideBarNav').hide();
            menuAnchors.addClass("hideAnchor");

            clearTimeout(timeoutScrollAnchor);
            anchorMenuMobile.removeClass("fixed");
            anchorActive="";
            $('.sidebarNav').removeClass('active');
        }
        
        $('.contentBody .elementAnchor').each(function () {
            var top = window.pageYOffset;
            var distance = top - $(this).offset().top;
            
            var hash = $(this).attr('id');
            
            var offset = 0;

            offset = 80;
            
            if (distance < 100 && distance > (-100-offset)) {
                $('.detailsArticle .sidebarNav ul li a.anchor').removeClass("active");
                $('.detailsArticle .sidebarNav ul li a.anchor[href="#'+hash+'"]').addClass("active");            
                
                var elem = $('#mob_'+hash);
                
                if(elem.offset()){	  
                            
                        if(anchorActive!=hash){
                            
                            clearTimeout(timeoutScrollAnchor);
                            
                            timeoutScrollAnchor = setTimeout(function(){
                                var elemtAnchor = $('#mob_'+hash).position().left;

                                var offsetSpace = 30;
                            
                                $(".sidebarNav .menuAnchors").animate({'scrollLeft': (elemtAnchor-offsetSpace)}, 300);				        
                            }, 500);
                            
                        }

                    $('.anchor').removeClass("active");
                    elem.addClass("active");
                }
                
                anchorActive=hash;
                
            }
            
        });
    }
});


function string_to_slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeiiiioooouuuunc------";

    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

if(document.location.hash!=""){
	console.log(document.location.hash);
	$('.detailsArticle .sidebarNav ul li a.anchor[href="'+document.location.hash+'"]').addClass("active");
    
}


//SCROLL STICK BONUS
var windowHeight =  $(window).height();
function isVisible($el) {
    var winTop = $(window).scrollTop();
    var winBottom = winTop + windowHeight;
    var elTop = $el.offset().top;
    var elBottom = elTop + $el.height();
    return ((elBottom<= winBottom) && (elTop >= winTop));
}

$(window).scroll(function() {
    const value = $('body').hasClass('scroll-up') ? '70px' : '130px'
    $('.contentBody .widget_affiliate').each(function(index) {
        if(isVisible($(this))){
            $('.contentBody .widget_affiliate').css('top', '-200px')
            $(this).css('top', value);
        }else{
            $(this).css('top', value);
        }
    });
});
